<template>
    <div class="modalIntroduction">
        <div class="content m-b-28">
            <span class="m-r-12"
                >바닐라브릿지,<br />
                <p class="f-bold">어떻게 이용하나요?</p></span
            >
            <p class="emoji">🙋‍♀️</p>
        </div>
        <button class="btn btn-primary" @click="goToIntro">이용방법 보러가기</button>
    </div>
</template>

<script>
export default {
    name: 'ModalIntroduction',
    methods: {
        goToIntro() {
            window.localStorage.setItem('readIntroduction', 'true')
            this.$router.push({ name: 'IntroductionPage' })
            this.$emit('close')
        },
    },
}
</script>

<style lang="scss" scoped>
.modalIntroduction {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 208px;
    border-radius: 12px 12px 0 0;
    padding: 24px;

    .content {
        height: 84px;
        color: black;
        font-size: 24px;
        letter-spacing: -0.4px;
        line-height: 1.58;

        @include flex-row;
        @include flex-between;
        @include items-center;
        @include f-regular;

        .emoji {
            font-family: AppleColorEmoji;
            font-size: 64px;
        }

        @media (max-width: 315px) {
            .emoji {
                display: none;
            }
        }
    }

    button {
        width: 100%;
        height: 48px;
        letter-spacing: -0.2px;
    }
}
</style>
